import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { InvestorCardDeckStyles } from '../../styles/Components/InvestorCardDeck/InvestorCardStyles';
import OptimizedImage from '../../utils/OptimizedImage';

const InvestorCardDeck = ({ component }) => {
  const { heading, companyList, scrollId } = component;

  return (
    <InvestorCardDeckStyles id={scrollId}>
      <Container>
        <Row className="heading-row">{heading && <h2>{heading}</h2>}</Row>
        <Row className="logo-container no-gutters">
          {companyList.map(company => (
            <Col lg={3} className="company-block m-2" key={company.id}>
              <figure>
                {company.logo.gatsbyImageData ? (
                  <OptimizedImage
                    image={company.logo.gatsbyImageData}
                    alt={company.name}
                    title={company?.logo?.title}
                  />
                ) : (
                  <OptimizedImage
                    src={company.logo.url}
                    alt={company.name}
                    title={company?.logo?.title}
                  />
                )}
              </figure>
            </Col>
          ))}
        </Row>
      </Container>
    </InvestorCardDeckStyles>
  );
};

export default InvestorCardDeck;
