import styled from 'styled-components';

export const InvestorCardDeckStyles = styled.div`
  padding: 96px 0;
  @media (max-width: 768px) {
    padding: 64px 0;
  }
  .heading-row {
    max-width: 800px;
    text-align: center;
    margin: 0 auto 56px;
    display: flex;
    justify-content: center;
  }

  .logo-container {
    display: flex;
    justify-content: space-evenly;
  }

  .company-block {
    height: 270px;
    width: 100%;
    max-width: 270px;
    box-shadow: 0 12px 16px rgba(106, 114, 158, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
  }
`;

export const InvestorCardStyles = styled.div``;
